import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import JumboApp from "@jumbo/components/JumboApp";
import AppLayout from "./AppLayout";
import JumboTheme from "@jumbo/components/JumboTheme";
import AppRoutes from "./AppRoutes";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import { CircularProgress } from "@mui/material";
import JumboAuthProvider from "@jumbo/components/JumboAuthProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SearchContextProvider from "@jumbo/components/JumboSearch/SearchContext";
import SocketProvider from "./providers/SocketProvider";
import { PartnerAuthProvider } from "./PartnerAuthProvider";

function App() {
  return (
    <BrowserRouter>
      <AppProvider>
        <DndProvider backend={HTML5Backend}>
          <JumboApp activeLayout={config.defaultLayout}>
            <JumboTheme init={config.theme}>
              <PartnerAuthProvider>
                <SearchContextProvider>
                  <JumboRTL>
                    <JumboDialogProvider>
                      <JumboDialog />
                      <SnackbarProvider
                        autoHideDuration={3000}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        maxSnack={3}
                      >
                        <JumboAuthProvider>
                          <SocketProvider>
                            <AppLayout>
                              <Suspense
                                fallback={
                                  <Div
                                    sx={{
                                      display: "flex",
                                      minWidth: 0,
                                      alignItems: "center",
                                      alignContent: "center",
                                      height: "100%",
                                    }}
                                  >
                                    <CircularProgress
                                      sx={{ m: "-40px auto 0" }}
                                    />
                                  </Div>
                                }
                              >
                                <AppRoutes />
                              </Suspense>
                            </AppLayout>
                          </SocketProvider>
                        </JumboAuthProvider>
                      </SnackbarProvider>
                    </JumboDialogProvider>
                  </JumboRTL>
                </SearchContextProvider>
              </PartnerAuthProvider>
            </JumboTheme>
          </JumboApp>
        </DndProvider>
      </AppProvider>
    </BrowserRouter>
  );
}

export default App;
