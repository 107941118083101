import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const EasyPayOrderStatusModal = ({ order, onStatusChange }) => {
  const { t } = useTranslation();
  const { processStatus } = order;

  return (
    <>
      <Button
        type="submit"
        variant="contained"
        size="large"
        style={{
          width: "250px",
          height: "150px",
          margin: "30px",
          backgroundColor: processStatus === "ready" ? "#dbd5d4" : "#60b527",
          color: "white",
          fontWeight: "bold",
        }}
        onClick={() => onStatusChange("ready", order)}
        disabled={processStatus === "ready" || processStatus === "done"}
      >
        {t("pages.easyPayOrders.ready")}
      </Button>

      <Button
        type="submit"
        variant="contained"
        size="large"
        disabled={processStatus === "inProgress" || processStatus === "done"}
        style={{ width: "250px", height: "150px", margin: "30px" }}
        onClick={() => onStatusChange("done", order)}
      >
        {t("pages.easyPayOrders.done")}
      </Button>
    </>
  );
};

export default EasyPayOrderStatusModal;
