import i18n from "app/config/i18n";

export const activeLocale = (lang) => {
  let language;
  switch (lang) {
    case "cs-CZ":
      language = "cs";
      break;
    case "en-EN":
      language = "en";
      break;
    case "de-DE":
      language = "de";
      break;
    default:
      language = "sk";
  }

  i18n.changeLanguage(language).then(() => {});
};

export default activeLocale;
