import { removeAuth } from "@jumbo/components/JumboAuthProvider/authHelpers";
import jwtAxios from "axios";

const BE_URL = process.env.REACT_APP_KIOSK_PARTNER_ACCESS_PROD_URL || "";
const jwtAuthKioskAxiosProductionPartner = jwtAxios.create({
  baseURL: `${BE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

jwtAuthKioskAxiosProductionPartner.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.message === "Unauthorised access") {
      delete jwtAuthKioskAxiosProductionPartner.defaults.headers.common[
        "Authorization"
      ];
      removeAuth();
    }
    return Promise.reject(err);
  }
);
export default jwtAuthKioskAxiosProductionPartner;
