import React, { Suspense } from "react";
import { IconButton, Stack } from "@mui/material";
import {
  dsokMenus,
  easyPayMenus,
  getHomeMenus,
  getPartnerMenu,
  kioskMenus,
  mediaMenus,
  productMenus,
} from "./menus";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboLayoutHeader from "@jumbo/hooks/useJumboLayoutHeader";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import LanguageSelector from "app/shared/widgets/LanguageSelector/LanguageSelector";
import AuthUserDropdown from "app/shared/widgets/AuthUserDropdown";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import adminMenus from "./adminMenus";
import MenuIcon from "@mui/icons-material/Menu";
import usePartnerAuth from "@jumbo/hooks/usePartnerAuth";
import { useSearchParams } from "react-router-dom";
import { is } from "date-fns/locale";

const Sidebar = () => {
  const { getAuthUser } = useJumboAuth();
  const { user: partnerUser } = usePartnerAuth();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const lang = searchParams.get("hl");
  const { customer, type } = getAuthUser() || {};
  // const isPartner = token && Object.keys(partnerUser || {}).length;
  const isPartner = window.location.href.includes("manage");
  const { kiosk, presentations, screens, templates, products, dsok, easyPay } =
    customer || {};
  const superadmin =
    customer?.customerType === 1 || customer?.customerType === 2;
  const dsokOperator = type === 4;
  const easyPayOperator = type === 5;
  const adminMenu = superadmin && adminMenus;
  const homeMenu = getHomeMenus({ presentations, screens, templates });
  const kioskMenu = kiosk && kioskMenus;

  const productMenu = products && productMenus;
  const mediaMenu = !dsokOperator && mediaMenus;
  const partnerMenu = getPartnerMenu({ partnerUser, token, lang });
  const dsokMenu = dsok && !easyPay && dsokMenus;
  const easyPayMenu = easyPay && easyPayMenus;
  const dsokOperatorMenu = dsok ? { ...dsokMenus } : {};
  dsokOperatorMenu.children = dsokOperatorMenu.children?.filter(
    (menu) => menu.operatorAccess
  );
  const others = (productMenu || kioskMenu || dsokMenu || easyPayMenu) && {
    label: "sidebar.menu.others",
    type: "section",
    icon: <MenuIcon />,
    children: [
      productMenu,
      kioskMenu,
      easyPayMenu,
      dsokOperator ? dsokOperatorMenu : dsokMenu,
      mediaMenu,
    ],
  };
  let menuList = isPartner
    ? [partnerMenu].filter((menu) => menu)
    : [homeMenu, adminMenu, others, dsokMenu].filter((menu) => menu);

  if (easyPayOperator) {
    menuList = [homeMenu, others].filter((menu) => menu);
  }
  return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menuList} />
        </Suspense>
      </JumboScrollbar>
      <Div
        sx={{
          display: "flex",
          justifyContent: "center",
          borderTop: "solid 1px",
          borderColor: "divider",
          marginX: "2px",
        }}
      >
        <Stack direction="column" alignItems="center" sx={{ ml: "auto" }}>
          <Suspense fallback={<></>}>
            <LanguageSelector align="center" />
          </Suspense>
          <AuthUserDropdown />
        </Stack>
      </Div>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { setHeaderOptions } = useJumboLayoutHeader();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  const handleOpen = () => {
    setSidebarOptions({ open: false });
    setHeaderOptions({ hide: false });
  };

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={handleOpen}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
