import React, { useEffect, useState } from "react";
import JumboListNoDataPlaceHolder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
  Card,
  Typography,
  Modal,
  Pagination,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Grid,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import JumboSearch from "@jumbo/components/JumboSearch";
import Div from "@jumbo/shared/Div/Div";
import {
  getCustomer,
  getEasyPayOrders,
  updateEasyPaiOrderProcessStaus,
} from "app/services/api/management";

import ClearIcon from "@mui/icons-material/Clear";
import jwtAuthAxiosKiosk from "app/services/auth/jwtAuthKioskStaging";
import { useSocket } from "app/providers/SocketProvider";
import OrderItem from "./OrderItem";
import EasyPayOrderStatusModal from "./EasyPayOrderStatusModal";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  width: { md: "auto", xs: "auto" },
  maxHeight: "100%",
  overflow: "scroll",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: { md: 4, xs: 1 },
};
const defaultFilter = {
  excludeDoneOrders: true,
};
const ITEM_PER_PAGE = 20;

const EasyPayOrders = () => {
  const { getAuthUser } = useJumboAuth();
  const {
    customer: { url, customerId },
  } = getAuthUser();

  const { socket, connect, unsubscribe, subscribe } = useSocket();
  const { t } = useTranslation();
  const [searchName, setSearchName] = useState();
  const [orderModal, setOrderModal] = useState();
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [orders, setOrders] = useState([]);
  const [defaultOrders, setDefaultOrders] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState();
  const customerUrl = url || "";

  const updateEasyOrderStatus = async (status, order) => {
    await updateEasyPaiOrderProcessStaus(order, status).then((res) => {});
    setOrderModal();
    loadOrders();
  };

  const loadOrders = async () => {
    setLoading(true);
    await getEasyPayOrders().then((orders) => {
      const orderArr = Object.keys(orders || {}).map((id) => ({
        ...orders[id],
      }));
      const filteredOrders = orderArr.filter((order) => {
        const conditions = [
          filter.excludeDoneOrders ? order.processStatus !== "done" : true,
        ];
        return conditions.every(Boolean);
      });
      const searchedOrders = searchName
        ? filteredOrders.filter((order) => {
            const conditions = [
              `${order?.internalOrderNumber.toLowerCase()}`?.includes(
                searchName
              ),
              `${order?.externalCode.toLowerCase()}`?.includes(searchName),
            ];
            return conditions.some(Boolean);
          })
        : filteredOrders;
      setOrders(searchedOrders || []);
      setDefaultOrders(orderArr || []);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (jwtAuthAxiosKiosk.defaults.baseURL) {
      connect(jwtAuthAxiosKiosk.defaults.baseURL);
    }
  }, [jwtAuthAxiosKiosk.defaults.baseURL]);

  const handleUpdate = (message) => {
    if (message === "refresh") {
      loadOrders();
    }
  };

  useEffect(() => {
    if (socket?.active) {
      subscribe(`admin/dsokOrder/${customerId}`, handleUpdate);
    }
    return () => {
      if (socket?.active) {
        unsubscribe(`admin/dsokOrder/${customerId}`, handleUpdate);
      }
    };
  }, [socket?.active]);

  const searchByName = async (searchName) => {
    setLoading(true);
    if (!jwtAuthAxiosKiosk.defaults.baseURL) {
      await loadCustomer();
    }
    searchName !== undefined && loadOrders();
  };

  useEffect(() => {
    searchByName(searchName);
    setPage(1);
  }, [searchName]);

  const loadCustomer = async () => {
    await getCustomer(customerUrl).then((customer) => setCustomer(customer));
  };

  useEffect(() => {
    loadCustomer();
  }, []);
  const updateFilter = (newFilter) => {
    setFilter({ ...filter, ...newFilter });
  };

  useEffect(() => {
    setPage(1);
    loadOrders();
  }, [filter]);

  return (
    <React.Fragment>
      <Typography variant={"h2"} mb={3}>
        {t("pages.easyPayOrders.title")}
      </Typography>
      <Grid container columnSpacing={2} rowGap={2} mb={2}>
        <Grid item md={6} xs={12}>
          <Card sx={{ mb: 0, width: "100%" }}>
            <JumboSearch
              page="DSOKOrders"
              onChange={setSearchName}
              sx={{
                width: "100%",
              }}
              placeholder={t("pages.presentation.searchPresentation")}
            />
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { md: "flex-end", xs: "center" },
            }}
          >
            <Pagination
              count={Math.ceil(orders?.length / ITEM_PER_PAGE)}
              color="primary"
              size={isMobile ? "small" : "medium"}
              siblingCount={0}
              boundaryCount={1}
              page={page}
              onChange={(_, val) => setPage(val)}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid item md={12} xs={12}>
        <Card sx={{ px: 3, mb: 3 }}>
          <Grid
            container
            columnSpacing={2}
            rowGap={2}
            mb={2}
            justifyContent="center"
          >
            <Grid item md={12} xs={12}>
              <Div sx={{ mt: 3, mb: 1, textAlign: "start" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={filter.excludeDoneOrders}
                      onChange={(e) =>
                        updateFilter({ excludeDoneOrders: e.target.checked })
                      }
                      name="excludeDoneOrders"
                    />
                  }
                  label={t("pages.easyPayOrders.excludeDoneOrders")}
                />
              </Div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {loading ? (
        <CircularProgress sx={{ m: "5px 50%" }} />
      ) : (
        <>
          {orders?.length ? (
            <>
              {orders.map((order, index) => {
                if (
                  index + 1 > ITEM_PER_PAGE * (page - 1) &&
                  index + 1 <= ITEM_PER_PAGE * page
                ) {
                  return (
                    <OrderItem
                      customer={customer}
                      key={index}
                      item={order}
                      onPreview={() =>
                        setOrderModal({ type: "previewOrder", order })
                      }
                      searchName={searchName}
                    />
                  );
                }
                return null;
              })}
            </>
          ) : (
            <JumboListNoDataPlaceHolder />
          )}
        </>
      )}
      <Modal open={orderModal !== undefined} onClose={() => setOrderModal()}>
        <Div sx={modalStyle}>
          <EasyPayOrderStatusModal
            order={orderModal?.order}
            onCancel={() => setOrderModal()}
            onStatusChange={(status, order) => {
              updateEasyOrderStatus(status, order);
            }}
          />
          <IconButton
            onClick={() => setOrderModal()}
            sx={{ position: "absolute", right: 10, top: 15 }}
          >
            <ClearIcon />
          </IconButton>
        </Div>
      </Modal>
    </React.Fragment>
  );
};

export default EasyPayOrders;
