import React, { useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import OrderForm from "./OrderForm";
import usePartnerAuth from "@jumbo/hooks/usePartnerAuth";
import { useParams, useSearchParams } from "react-router-dom";
import { getCustomer, getOrder } from "app/services/api/partner";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import activeLocale from "app/services/partner/activeLocale";
import InvalidTokenMessage from "../invalidTokenMessage";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useJumboTheme } from "@jumbo/hooks";

const OrderDetail = () => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState();

  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [customer, setCustomer] = useState();
  const [searchParams] = useSearchParams();
  const { externalOrderId } = useParams();
  const { validate } = usePartnerAuth();
  const token = searchParams.get("token");
  const lang = searchParams.get("hl");
  // const [envType, setEnvType] = useState();
  const [tokenChecked, setTokenChecked] = useState(false);
  const { theme } = useJumboTheme();

  const orderTitle = order?.externalOrderId
    ? order?.orderCode + " ( " + order?.externalOrderId + " )"
    : order?.orderCode;

  useEffect(() => {
    activeLocale(lang);
    if (token) {
      setTokenChecked(true);
      validate(token)
        .then((res) => {
          setValidated(true);
          // setEnvType("prod");
          let envType = "prod";
          console.log("envType", envType);
          if (
            res?.iss === "smolkoland.ikelp.com" ||
            res?.iss === "skusit.ikelp.com" ||
            res?.iss === "intesso.ikelp.com"
          ) {
            // setEnvType("staging");
            envType = "staging";
          }
          console.log("envType", envType);
          loadData(envType);
        })
        .catch((error) => {
          setValidated(false);
          console.log("Token validation failed", error);
        });
    }
  }, [token]);

  const loadOrder = async (envType) => {
    setLoading(true);
    if (envType === undefined) return;
    const order = await getOrder(externalOrderId, token, envType);
    setOrder(order);
    setLoading(false);
  };

  const loadCustomer = async (envType) => {
    if (!envType) return;
    await getCustomer(token, envType).then((customer) => setCustomer(customer));
  };

  const loadData = async (envType) => {
    await loadCustomer(envType);
    await loadOrder(envType);
  };

  useEffect(() => {
    // loadData();
  }, [token]);

  if (tokenChecked && !validated) {
    return InvalidTokenMessage();
  }

  return (
    <React.Fragment>
      <Typography variant={"h2"} mb={3}>
        {t("pages.dsokOrders.orderDetail")} - {customer?.companyName}
      </Typography>
      {order ? (
        <JumboCardQuick
          title={
            <Typography
              variant={"h4"}
              mb={0}
              sx={{
                minWidth: 245,
                [theme.breakpoints.down("md")]: {
                  minWidth: "100%",
                  marginBottom: 2,
                },
              }}
            >
              {`#${orderTitle || ""} ${order?.demo ? " - DEMO" : ""}`}
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: "divider",
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          sx={{
            flex: 1,
            mr: 2,
          }}
          wrapperSx={{
            p: 1,
            "&:last-child": {
              pb: 2,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          <OrderForm
            order={order}
            currencyCode={customer?.dsokSettings?.customer?.currencyCode}
          />
        </JumboCardQuick>
      ) : (
        <JumboListNoDataPlaceholder />
      )}
    </React.Fragment>
  );
};

export default OrderDetail;
