import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tab,
  Typography,
  Switch,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, useFormikContext } from "formik";
import { Label } from "app/pages/presentation/module/form/types";
import { LANGUAGES } from "app/utils/constants/settings";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ClearIcon from "@mui/icons-material/Clear";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as Yup from "yup";
import { format, parse } from "date-fns";
import CachedIcon from "@mui/icons-material/Cached";
import { triggerDSOKBuild } from "app/services/api/management";
import { useSnackbar } from "notistack";

const FIELD_NAME = "LOCATIONS_FIELD";

export const getLocationsFieldSchema = (locations) => {
  const schema = {};
  const locationKeys = Object.keys(locations || {});
  if (!locationKeys?.length) {
    return schema;
  }
  locationKeys.forEach((location) => {
    schema[location] = {
      code: "",
      languageCode: "sk",
      country_id: "",
      street: "",
      postcode: "",
      city: "",
      firstname: "",
      lastname: "",
      telephone: "",
    };
  });
  return schema;
};

export const getLocationsFieldValidationSchema = (t) => ({
  code: Yup.string().required(t("messages.checkRequiredFields")),
  languageCode: Yup.string().required(t("messages.checkRequiredFields")),
  country_id: Yup.string().required(t("messages.checkRequiredFields")),
  street: Yup.string().required(t("messages.checkRequiredFields")),
  postcode: Yup.string().required(t("messages.checkRequiredFields")),
  city: Yup.string().required(t("messages.checkRequiredFields")),
  firstname: Yup.string().required(t("messages.checkRequiredFields")),
  lastname: Yup.string().required(t("messages.checkRequiredFields")),
  telephone: Yup.string().required(t("messages.checkRequiredFields")),
});

const LocationsField = ({
  handleChange,
  expanded,
  isPartner,
  isAdmin,
  isPartnerAdmin,
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [selectedLocation, setSelectedLocation] = useState("1");
  const [updating, setUpdating] = useState();
  const { showDialog, hideDialog } = useJumboDialog();
  const { enqueueSnackbar } = useSnackbar();

  const locationKeys = Object.keys(values.customer.locations || {});

  const handleAddLocation = () => {
    setFieldValue("customer.locations", {
      ...values.customer.locations,
      [locationKeys.length + 1]: { languageCode: "sk" },
    });
  };

  const handleDeleteLocation = (location) => {
    const newLocations = {};
    let deleted = 0;
    locationKeys.forEach((key, index) => {
      if (key === location) {
        deleted++;
        return;
      }
      newLocations[index + 1 - deleted] = values.customer.locations[key];
    });
    setFieldValue("customer.locations", newLocations);
    setSelectedLocation(locationKeys[0]);
    hideDialog();
  };

  const handleBuildApp = async (url) => {
    setUpdating(true);
    try {
      await triggerDSOKBuild(url);
      const message = `${t("buttons.buildApp")} ${t(
        "notifications.wasSuccessful"
      )}`;
      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      const message = `${t("buttons.buildApp")} ${t(
        "notifications.wasFailed"
      )}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
    setUpdating(false);
    hideDialog();
  };

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.locations")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          spacing={1}
          px={2}
        >
          <TabContext value={selectedLocation}>
            <Stack width="100%">
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                }}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <TabList onChange={(_, val) => setSelectedLocation(val)}>
                  {locationKeys.map((item) => {
                    return (
                      <Tab
                        key={item}
                        label={
                          <Stack direction="row" sx={{ alignItems: "center" }}>
                            {item}
                            {!isPartner && (
                              <IconButton
                                onClick={() =>
                                  showDialog({
                                    variant: "confirm",
                                    title: t(
                                      "widgets.confirmDialog.areYouSure"
                                    ),
                                    onYes: () => handleDeleteLocation(item),
                                    onNo: () => hideDialog(),
                                  })
                                }
                              >
                                <ClearIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Stack>
                        }
                        value={item}
                      />
                    );
                  })}
                </TabList>
                {!isPartnerAdmin && (
                  <IconButton onClick={handleAddLocation}>
                    <AddCircleIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
              {!locationKeys?.length ? (
                <JumboListNoDataPlaceholder />
              ) : (
                locationKeys.map((location) => {
                  return (
                    <TabPanel
                      key={`tab_${location}`}
                      value={location}
                      sx={{
                        pb: 1,
                        pr: 0,
                        pl: 0,
                      }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 1, mb: 1 }}>
                            <JumboTextField
                              fullWidth
                              required
                              name={`customer.locations.${location}.code`}
                              label={t("pages.dsokDetails.code")}
                              InputProps={{
                                readOnly: isPartner,
                              }}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                            <JumboTextField
                              required
                              select
                              InputLabelProps={{ required: false }}
                              InputProps={{
                                readOnly: isPartner,
                              }}
                              name={`customer.locations.${location}.languageCode`}
                              label={
                                <Label
                                  text={t("pages.dsokDetails.language")}
                                  required
                                />
                              }
                            >
                              {LANGUAGES.map((language, index) => {
                                return (
                                  <MenuItem key={index} value={language.code}>
                                    {language.name}
                                  </MenuItem>
                                );
                              })}
                            </JumboTextField>
                          </FormControl>
                        </Grid>
                        {!isPartner && (
                          <Grid item xs={12} md={4}>
                            <Div sx={{ mt: 1, mb: 1 }}>
                              <JumboTextField
                                required
                                fullWidth
                                name={`customer.locations.${location}.country_id`}
                                label={t("pages.dsokDetails.countryId")}
                              />
                            </Div>
                          </Grid>
                        )}

                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 1, mb: 1 }}>
                            <JumboTextField
                              required
                              fullWidth
                              name={`customer.locations.${location}.street`}
                              InputProps={{
                                readOnly: isPartner,
                              }}
                              label={t("pages.editDevice.street")}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 1, mb: 1 }}>
                            <JumboTextField
                              required
                              fullWidth
                              InputProps={{
                                readOnly: isPartner,
                              }}
                              name={`customer.locations.${location}.postcode`}
                              label={t("pages.editDevice.zip")}
                            />
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 1, mb: 1 }}>
                            <JumboTextField
                              required
                              fullWidth
                              InputProps={{
                                readOnly: isPartner,
                              }}
                              name={`customer.locations.${location}.city`}
                              label={t("pages.editDevice.city")}
                            />
                          </Div>
                        </Grid>
                        {!isPartner && (
                          <>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  required
                                  fullWidth
                                  name={`customer.locations.${location}.firstname`}
                                  label={t("pages.dsokDetails.firstname")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  fullWidth
                                  required
                                  name={`customer.locations.${location}.lastname`}
                                  label={t("pages.dsokDetails.lastname")}
                                />
                              </Div>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Div sx={{ mt: 1, mb: 1 }}>
                                <JumboTextField
                                  required
                                  fullWidth
                                  name={`customer.locations.${location}.telephone`}
                                  label={t("pages.editDevice.phone")}
                                />
                              </Div>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 1, mb: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                required
                                ampm={false}
                                sx={{ width: "100%" }}
                                name={`customer.locations.${location}.timeFrom`}
                                label={
                                  <Label
                                    text={t("pages.dsokDetails.timeFrom")}
                                    helpText={t(
                                      "pages.dsokDetails.timeFromHelp"
                                    )}
                                    sx={{ color: "text.primary" }}
                                    required={false}
                                  />
                                }
                                slotProps={{
                                  textField: {
                                    InputLabelProps: { shrink: true },
                                  },
                                }}
                                value={parse(
                                  values.customer.locations[location].timeFrom,
                                  "HH:mm:ss",
                                  new Date()
                                )}
                                onChange={(newValue) =>
                                  setFieldValue(
                                    `customer.locations.${location}.timeFrom`,
                                    format(newValue, "HH:mm:ss")
                                  )
                                }
                                readOnly={isPartnerAdmin}
                              />
                            </LocalizationProvider>
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Div sx={{ mt: 1, mb: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                required
                                ampm={false}
                                sx={{ width: "100%" }}
                                name={`customer.locations.${location}.timeTo`}
                                label={t("pages.dsokDetails.timeTo")}
                                slotProps={{
                                  textField: {
                                    InputLabelProps: { shrink: true },
                                  },
                                }}
                                value={parse(
                                  values.customer.locations[location].timeTo,
                                  "HH:mm:ss",
                                  new Date()
                                )}
                                onChange={(newValue) =>
                                  setFieldValue(
                                    `customer.locations.${location}.timeTo`,
                                    format(newValue, "HH:mm:ss")
                                  )
                                }
                                readOnly={isPartnerAdmin}
                              />
                            </LocalizationProvider>
                          </Div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Grid item xs={12} md={4}>
                            <Div sx={{ mt: 1, mb: 1 }}>
                              <FormControlLabel
                                control={
                                  <Field
                                    name={`customer.locations.${location}.bottomMessage`}
                                    component={Switch}
                                    onChange={(e) =>
                                      setFieldValue(
                                        `customer.locations.${location}.bottomMessage`,
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                                label={
                                  <Label
                                    text={t("pages.dsokDetails.bottomMessage")}
                                    helpText={t(
                                      "pages.dsokDetails.bottomMessageHelp"
                                    )}
                                    sx={{ color: "text.primary" }}
                                    required={false}
                                  />
                                }
                                checked={
                                  values.customer.locations[location]
                                    .bottomMessage
                                }
                              />
                            </Div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TabPanel>
                  );
                })
              )}
            </Stack>
          </TabContext>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default LocationsField;
