import React from "react";
import GroupIcon from "@mui/icons-material/Group";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const adminMenus = {
  label: "sidebar.menu.management",
  type: "section",
  children: [
    {
      uri: "/customers",
      label: "sidebar.menuItem.customers",
      type: "nav-item",
      icon: <GroupIcon sx={{ fontSize: 20 }} />,
    },
    {
      uri: "/dsok/customers",
      label: "sidebar.menuItem.dsokCustomers",
      type: "nav-item",
      icon: <TouchAppIcon sx={{ fontSize: 20 }} />,
    },
  ],
};

export default adminMenus;
