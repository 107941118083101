import Div from "@jumbo/shared/Div";
import React, { useEffect, useState } from "react";
import { enUS, skSK, csCZ, deDE } from "@mui/material/locale";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { useTranslation } from "react-i18next";

const languages = [
  {
    locale: "en-US",
    language: "en",
    muiLocale: enUS,
    flag: "en_flag.jpg",
  },
  {
    locale: "sk-SK",
    language: "sk",
    muiLocale: skSK,
    flag: "sk_flag.jpg",
  },
  {
    locale: "cs-CZ",
    language: "cs",
    muiLocale: csCZ,
    flag: "cz_flag.jpg",
  },
  {
    locale: "de-DE",
    language: "de",
    muiLocale: deDE,
    flag: "de_flag.jpg",
  },
];

const LanguageSelector = ({ align }) => {
  const { setMuiLocale } = useJumboTheme();
  const { i18n } = useTranslation();
  const [activeLocale, setActiveLocale] = useState();

  useEffect(() => {
    if (i18n.language) {
      setActiveLocale(i18n.language);
    }
  }, [i18n.language]);

  const handleChange = (locale) => {
    const localeIndex = languages.findIndex(
      (language) => language.locale === locale
    );
    if (localeIndex !== -1) {
      i18n.changeLanguage(languages[localeIndex].language).then(() => {
        setActiveLocale(languages[localeIndex].language);
        setMuiLocale(languages[localeIndex].muiLocale);
      });
    }
  };
  return (
    <Div sx={{ mt: 1, textAlign: align }}>
      {languages.map((language) => (
        <img
          key={language.locale}
          src={`${ASSET_IMAGES}/flag/${language.flag}`}
          style={{
            width: "15%",
            minWidth: 30,
            padding: 5,
            cursor: "pointer",
            opacity: `${activeLocale === language.language ? "1.0" : "0.5"}`,
          }}
          onClick={() => handleChange(language.locale)}
          alt={language.locale}
        />
      ))}
    </Div>
  );
};

export default LanguageSelector;
