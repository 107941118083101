import { removeAuth } from "@jumbo/components/JumboAuthProvider/authHelpers";
import jwtAxios from "axios";
const BE_URL = process.env.REACT_APP_KIOSK_PARTNER_ACCESS_STAGING_URL || "";
const jwtAuthKioskAxiosStagingPartner = jwtAxios.create({
  baseURL: `${BE_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

jwtAuthKioskAxiosStagingPartner.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.message === "Unauthorised access") {
      delete jwtAuthKioskAxiosStagingPartner.defaults.headers.common[
        "Authorization"
      ];
      removeAuth();
    }
    return Promise.reject(err);
  }
);
export default jwtAuthKioskAxiosStagingPartner;
