import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Switch,
  Tab,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import Div from "@jumbo/shared/Div/Div";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, useFormikContext } from "formik";
import * as Yup from "yup";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import mapValues from "lodash/mapValues";
import { Label } from "app/pages/presentation/module/form/types";
import { BAR_CODE_TYPES } from "app/utils/constants/settings";

const FIELD_NAME = "PRINTER_SETTINGS_FIELD";

export const getPrinterSettingsFieldSchema = (languages) => {
  const schema = {
    status: "",
    bottomMessage: "",
    dineInSentence: "",
    dineInButtonTitle: "",
    takeAwaySentence: "",
    takeAwayInformation: "",
    takeAwayHelp: "",
    tajeAwayButtonTitle: "",
    pickupOrderText: "",
    message: {},
    bottomMessageStatus: {},
    qrCodeStatus: {},
    barCodeStatus: {},
  };
  languages?.forEach((lang) => (schema.message[lang] = ""));
  return;
};

export const getPrinterSettingsFieldValidationSchema = (t) => ({
  message: Yup.lazy((lazyObj) =>
    Yup.object(
      mapValues(lazyObj, () =>
        Yup.string().required(t("messages.checkRequiredFields"))
      )
    )
  ),
});

const PrinterSettingsFields = ({ handleChange, expanded }) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    values.customer.languages &&
      setSelectedLanguage(values.customer.languages[0]);
  }, [values.customer.languages]);

  const topMessages = [
    "message1",
    "message2",
    "message3",
    "message4",
    "message5",
  ];
  const bottomMessages = ["message1", "message2", "message3"];
  const footerMessages = ["message1", "message2"];

  return (
    <Accordion
      expanded={expanded === FIELD_NAME}
      onChange={handleChange(FIELD_NAME)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ width: "33%", flexShrink: 0 }}>
          {t("pages.dsokDetails.printerSettings")}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
              <FormControlLabel
                control={
                  <Field
                    name="printerSettings.status"
                    component={Switch}
                    onChange={(e) =>
                      setFieldValue("printerSettings.status", e.target.checked)
                    }
                  />
                }
                label={
                  <Label
                    text={t("pages.dsokDetails.enableDirectPrintReceipt")}
                    helpText={t(
                      "pages.dsokDetails.enableDirectPrintReceiptHelp"
                    )}
                    sx={{ color: "text.primary" }}
                    required={false}
                  />
                }
                checked={values.printerSettings?.status}
              />
            </Div>
          </Grid>
          {values.printerSettings?.status && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <Label
                    text={t("pages.dsokDetails.directPrintQrCode")}
                    required={false}
                    sx={{ color: "text.primary" }}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="printerSettings.qrCodeStatus"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "printerSettings.qrCodeStatus",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={
                      <Label
                        text={t("pages.dsokDetails.qrCodeStatus")}
                        sx={{ color: "text.primary" }}
                        required={false}
                      />
                    }
                    checked={values.printerSettings?.qrCodeStatus}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <Div sx={{ mb: 1, mx: 0 }}>
                  <JumboTextField
                    fullWidth
                    name={`printerSettings.qrCode`}
                    label={t(`pages.dsokDetails.qrCode`)}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  <Label
                    text={t("pages.dsokDetails.directPrintBarCode")}
                    required={false}
                    sx={{ color: "text.primary" }}
                    helpText={t("pages.dsokDetails.directPrintBarCodeHelp")}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                  <FormControlLabel
                    control={
                      <Field
                        name="printerSettings.barCodeStatus"
                        component={Switch}
                        onChange={(e) =>
                          setFieldValue(
                            "printerSettings.barCodeStatus",
                            e.target.checked
                          )
                        }
                      />
                    }
                    label={
                      <Label
                        text={t("pages.dsokDetails.barCodeStatus")}
                        sx={{ color: "text.primary" }}
                        required={false}
                      />
                    }
                    checked={values.printerSettings?.barCodeStatus}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
                  <JumboTextField
                    required
                    select
                    InputLabelProps={{ required: false }}
                    name={`printerSettings.barcode.type`}
                    label={<Label text={t("pages.dsokDetails.barCodeType")} />}
                  >
                    {BAR_CODE_TYPES.map((item) => {
                      return (
                        <MenuItem key={item?.code} value={item?.code}>
                          {item?.name}
                        </MenuItem>
                      );
                    })}
                  </JumboTextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Div sx={{ mt: 1, mb: 1 }}>
                  <JumboTextField
                    fullWidth
                    name={`printerSettings.barcode.value`}
                    label={t(`pages.dsokDetails.barCodeValue`)}
                  />
                </Div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TabContext value={selectedLanguage}>
                  <Stack sx={{ mb: 2 }}>
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: "divider",
                      }}
                    >
                      <TabList onChange={(_, val) => setSelectedLanguage(val)}>
                        {values.customer.languages?.map((lang) => {
                          return (
                            <Tab
                              key={lang}
                              label={t(`languages.${lang}`)}
                              value={lang}
                            />
                          );
                        })}
                      </TabList>
                    </Box>
                    {values.customer.languages?.map((lang) => {
                      return (
                        <>
                          <TabPanel
                            key={`panel_${lang}`}
                            value={lang}
                            sx={{ pb: 1, pr: 0, minHeight: 350, pl: 1 }}
                          >
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              <Label
                                text={t(
                                  "pages.dsokDetails.directPrintTopMessages"
                                )}
                                helpText={t(
                                  "pages.dsokDetails.directPrintTopMessagesHelp"
                                )}
                                required={false}
                                sx={{ color: "text.primary" }}
                              />
                            </Typography>
                            <br />

                            {topMessages.map((message, key) => {
                              const index = key + 1;
                              return (
                                <Div sx={{ mb: 1, mx: 0 }}>
                                  <JumboTextField
                                    fullWidth
                                    name={`printerSettings.topMessage.${message}.${lang}`}
                                    label={
                                      t(`pages.dsokDetails.printMessage`) +
                                      " " +
                                      index
                                    }
                                  />
                                </Div>
                              );
                            })}
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              <Label
                                text={t(
                                  "pages.dsokDetails.directPrintBottomMessages"
                                )}
                                helpText={t(
                                  "pages.dsokDetails.directPrintBottomMessagesHelp"
                                )}
                                required={false}
                                sx={{ color: "text.primary" }}
                              />
                            </Typography>
                            <br />
                            {bottomMessages.map((message, key) => {
                              const index = key + 1;
                              return (
                                <Div sx={{ mb: 1, mx: 0 }}>
                                  <JumboTextField
                                    fullWidth
                                    name={`printerSettings.bottomMessage.${message}.${lang}`}
                                    label={
                                      t(`pages.dsokDetails.printMessage`) +
                                      " " +
                                      index
                                    }
                                  />
                                </Div>
                              );
                            })}
                            <Typography sx={{ width: "33%", flexShrink: 0 }}>
                              <Label
                                text={t(
                                  "pages.dsokDetails.directPrintFooterMessages"
                                )}
                                helpText={t(
                                  "pages.dsokDetails.directPrintFooterMessagesHelp"
                                )}
                                required={false}
                                sx={{ color: "text.primary" }}
                              />
                            </Typography>
                            <br />
                            {bottomMessages.map((message, key) => {
                              const index = key + 1;
                              return (
                                <Div sx={{ mb: 1, mx: 0 }}>
                                  <JumboTextField
                                    fullWidth
                                    name={`printerSettings.footerMessage.${message}.${lang}`}
                                    label={
                                      t(`pages.dsokDetails.printMessage`) +
                                      " " +
                                      index
                                    }
                                  />
                                </Div>
                              );
                            })}
                          </TabPanel>
                        </>
                      );
                    })}
                  </Stack>
                </TabContext>
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PrinterSettingsFields;
