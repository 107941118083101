import React, { useEffect, useState } from "react";
import EditModule from "../EditModule";
import ModuleSettings from "../ModuleSettings";
import FormController from "../form/FormController";

const VideoPlayerModule = ({ module, presentation, loadModule, type }) => {
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState({});
  const [newTextAreaData, setNewTextAreaData] = useState();

  useEffect(() => {
    setSettings(module?.parameters);
    setNewTextAreaData(settings?.text_area_simple);
  }, [module]);

  return (
    <EditModule
      type={type}
      module={module}
      presentation={presentation}
      loadModule={loadModule}
      settings={
        module &&
        module.parameters && (
          <ModuleSettings module={module}>
            <FormController setSettings={setSettings} setErrors={setErrors} />
          </ModuleSettings>
        )
      }
      updateValue={{
        settings: {
          url: module?.setting?.url,
          parameters: { ...settings, text_area_simple: newTextAreaData },
        },
        errors: errors,
      }}
    />
  );
};

export default VideoPlayerModule;
