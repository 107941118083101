import JumboCardQuick from "@jumbo/components/JumboCardQuick/JumboCardQuick";
import Div from "@jumbo/shared/Div/Div";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import SyncIcon from "@mui/icons-material/Sync";
import { Grid, Typography } from "@mui/material";
import { getCallScreenOrders } from "app/services/api/management";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSocket } from "app/providers/SocketProvider";
import moment from "moment/moment";

const CallScreen = () => {
  const { socket, connect, unsubscribe, subscribe } = useSocket();
  const { customerCode, locationCode, env, languages, timeInterval } =
    useParams();
  const { t, i18n } = useTranslation();
  const languagesList = languages.split(",");
  const [currentLanguage, setCurrentLanguage] = useState(languagesList[0]);
  const [currentDate, setCurrentDate] = useState(formatDateTime());
  const [currentTime, setCurrentTime] = useState(formatDateTime());

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadOrders, setLoadOrders] = useState(true);
  const baseURL =
    env === "stage"
      ? process.env.REACT_APP_KIOSK_PARTNER_ACCESS_STAGING_URL
      : process.env.REACT_APP_KIOSK_PARTNER_ACCESS_PROD_URL;

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);
    if (languagesList.length > 1) {
      const changeLanguage = () => {
        const currentIndex = languagesList.indexOf(currentLanguage);
        const nextIndex = (currentIndex + 1) % languagesList.length;
        setCurrentLanguage(languagesList[nextIndex]);
        i18n.changeLanguage(languagesList[nextIndex]);
      };
      const interval = setInterval(changeLanguage, timeInterval || 15000); // Change language every 15 seconds or get settings from url
      return () => clearInterval(interval);
    }
  }, [currentLanguage, i18n]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCallScreenOrders(
          customerCode,
          locationCode,
          baseURL
        );
        if (!response) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = response?.orders;
        setData(result);
        setLoadOrders(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [loadOrders]);

  useEffect(() => {
    if (socket?.active) {
      subscribe(
        `admin/easyPayProcessOrderChange/${locationCode}`,
        handleUpdate
      );
    }
    return () => {
      if (socket?.active) {
        unsubscribe(
          `admin/easyPayProcessOrderChange/${locationCode}`,
          handleUpdate
        );
      }
    };
  }, [socket?.active]);

  useEffect(() => {
    connect(baseURL);
  }, [baseURL]);

  useEffect(() => {
    const dateTimeInterval = setInterval(() => {
      setCurrentDate(formatDateTime("date"));
      setCurrentTime(formatDateTime("time"));
    }, 1000); // Update date and time every second

    return () => clearInterval(dateTimeInterval);
  }, []);

  useEffect(() => {
    document.body.classList.add("call-screen-body");
    return () => {
      document.body.classList.remove("call-screen-body");
    };
  }, []);

  const handleUpdate = (message) => {
    if (message === "refresh") {
      setLoadOrders(true);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const readyColor = "#18B12A";
  const inProgressColor = "#189AB1";
  const orderCountStyle = {
    fontSize: "3rem",
    textAlign: "center",
  };
  const inProgressOrdersStyle = {
    fontSize: "3rem",
    textAlign: "left",
    color: "#ffffff",
    height: "460px",
  };
  const readyOrdersStyle = {
    fontSize: "3rem",
    textAlign: "left",
    color: "#ffffff",
    height: "460px",
  };
  const dateStyle = {
    fontSize: "2rem",
    textAlign: "right",
    color: "#ffffff",
  };
  const timeStyle = {
    fontSize: "2rem",
    textAlign: "left",
    color: "#ffffff",
    paddingLeft: "5px",
  };
  const topMessageStyle = {
    fontSize: "2rem",
    textAlign: "center",
    color: "#ffffff",
  };

  const orderStyleInProgress = {
    fontSize: "3rem",
    textAlign: "center",
    color: inProgressColor,
  };

  const orderStyleReady = {
    fontSize: "3rem",
    textAlign: "center",
    color: readyColor,
  };

  const iconStyle = { fontSize: "6rem" };
  const headerStyle = { fontSize: "2rem" };
  const inProgressOrders = data?.inProgressOrders.join(
    `<span style="color:${inProgressColor}"> | </span>`
  );
  const readyOrders = data?.readyOrders.join(
    `<span style="color:${readyColor}"> | </span>`
  );

  return (
    <Div>
      {/* <Container sx={{}}> */}
      <Div>
        <Grid container spacing={4} alignItems={"stretch"}>
          <Grid item xs={1} md={1}>
            <Div style={timeStyle}>{currentTime}</Div>
          </Grid>
          <Grid item xs={9} md={9}>
            <Div style={topMessageStyle}>
              {t("pages.easyPayCallScreen.screenInfoText")}
            </Div>
          </Grid>
          <Grid item xs={2} md={2}>
            <Div style={dateStyle}>{currentDate}</Div>
          </Grid>
          <Grid item xs={4} md={4}>
            <JumboCardQuick
              headerSx={{ mb: 0, pb: 0 }}
              bgColor={inProgressColor}
              wrapper
            >
              <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={3} md={3}>
                  <SyncIcon style={iconStyle} sx={{ color: "#ffffff" }} />
                </Grid>
                <Grid item xs={9} md={9}>
                  <Typography variant="h3" align="center" style={headerStyle}>
                    {t("pages.easyPayCallScreen.inProgressBox")}
                  </Typography>
                  <Div style={orderCountStyle}>
                    {data?.inProgressOrdersCount}
                  </Div>
                </Grid>
              </Grid>
            </JumboCardQuick>
          </Grid>
          <Grid item xs={4} md={4}>
            <JumboCardQuick
              headerSx={{ mb: 0, pb: 0 }}
              bgColor={readyColor}
              wrapper
            >
              <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={3} md={3}>
                  <SentimentSatisfiedAltIcon
                    style={iconStyle}
                    sx={{ color: "#ffffff" }}
                  />
                </Grid>
                <Grid item xs={9} md={9}>
                  <Typography variant="h3" align="center" style={headerStyle}>
                    {t("pages.easyPayCallScreen.readyBox")}
                  </Typography>
                  <Div style={orderCountStyle}>{data?.readyOrdersCount}</Div>
                </Grid>
              </Grid>
            </JumboCardQuick>
          </Grid>
          <Grid item xs={4} md={4}>
            <JumboCardQuick
              headerSx={{ mb: 0, pb: 0 }}
              bgColor={"#B3C13D"}
              wrapper
            >
              <Grid container spacing={2} alignItems={"stretch"}>
                <Grid item xs={3} md={3}>
                  <DoneAllIcon style={iconStyle} sx={{ color: "#ffffff" }} />
                </Grid>
                <Grid item xs={9} md={9}>
                  <Typography variant="h3" align="center" style={headerStyle}>
                    {t("pages.easyPayCallScreen.todayProcessed")}
                  </Typography>
                  <Div style={orderCountStyle}>{data?.doneOrdersCount}</Div>
                </Grid>
              </Grid>
            </JumboCardQuick>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems={"stretch"} marginTop={"50px"}>
          <Grid item xs={6} md={6}>
            <JumboCardQuick
              title={
                <Typography
                  variant="h3"
                  align="center"
                  style={orderStyleInProgress}
                >
                  {t("pages.easyPayCallScreen.inProgressOrders")}
                </Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
              // bgColor={"#189AB1"}
              bgColor={"#000000"}
              style={{ border: "2px solid #ffffff" }}
              wrapper
            >
              <Div
                style={inProgressOrdersStyle}
                dangerouslySetInnerHTML={{
                  __html: inProgressOrders,
                }}
              />
            </JumboCardQuick>
          </Grid>
          <Grid item xs={6} md={6}>
            <JumboCardQuick
              title={
                <Typography variant="h3" align="center" style={orderStyleReady}>
                  {t("pages.easyPayCallScreen.readyOrders")}
                </Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
              // bgColor={"#18B12A"}
              bgColor={"#000000"}
              style={{ border: "2px solid #ffffff" }}
              wrapper
            >
              <Div
                style={readyOrdersStyle}
                dangerouslySetInnerHTML={{
                  __html: readyOrders,
                }}
              />
            </JumboCardQuick>
          </Grid>
        </Grid>
      </Div>
    </Div>
  );
};

const formatDateTime = (type = "date") => {
  const format = type === "date" ? "DD.MM.YYYY" : "HH:mm:ss";
  return moment().format(format);
};
export default CallScreen;
