import { LAYOUT_CONTAINER_STYLES } from "@jumbo/utils/constants/layout";
import { mainTheme } from "../themes/main/default";
import { headerTheme } from "../themes/header/default";
import { sidebarTheme } from "../themes/sidebar/default";
import { footerTheme } from "../themes/footer/default";
import LAYOUT_NAMES from "../layouts/layouts";
import { createJumboTheme } from "@jumbo/utils";
import jwtAuthAxios from "../services/auth/jwtAuth";
import jwtAuthKioskAxios from "../services/auth/jwtAuthKiosk";
import jwtAuthKioskStagingAxios from "../services/auth/jwtAuthKioskStaging";
import jwtAuthKioskProductionAxios from "../services/auth/jwtAuthKioskProduction";
import authServices from "../services/auth-services";

const config = {
  defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
  containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,
  authSetting: {
    axiosObject: jwtAuthAxios,
    axiosKioskObject: jwtAuthKioskAxios,
    axiosKioskStagingObject: jwtAuthKioskStagingAxios,
    axiosKioskProductionObject: jwtAuthKioskProductionAxios,
    fallbackPath: "/user/login",
    getAuthUserService: authServices.getCurrentUser,
    redirectNotAuthenticatedPath: "/",
  },

  theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),
};

export { config };
