import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Accordion, Card, Typography, Button } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const OrderItem = ({ item, onPreview, customer }) => {
  const { t } = useTranslation();
  const { dsokSettings } = customer || {};
  const devices = dsokSettings?.customer?.devices;
  let deviceName = "";
  const processStatus = item?.processStatus || "inProgress";
  for (const key in devices) {
    const device = devices[key];
    if (device?.uuid === item.kioskUUID) {
      deviceName = device?.deviceName;
    }
  }

  let buttonStyle = {};

  // "#60b527"
  if (processStatus === "ready") {
    buttonStyle = {
      backgroundColor: "#60b527",
    };
  }
  const InitialStatusIcon = ({ status }) =>
    status ? (
      <CheckCircleIcon sx={{ color: "#2C9E4B" }} />
    ) : (
      <HighlightOffIcon sx={{ color: "#FF0000" }} />
    );

  return (
    <Card sx={{ mb: 1 }}>
      <Accordion expanded={false} square sx={{ borderRadius: 2 }}>
        <AccordionSummary
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            px: 3,
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-content": {
              alignItems: "center",
              justifyContent: "space-between",

              "&.Mui-expanded": {
                margin: "12px 0",
              },
            },
            ".MuiAccordionSummary-expandIconWrapper": {
              color: "text.secondary",
              transform: "none",
              height: 28,
              width: 28,
              alignItems: "center",
              justifyContent: "center",
              mr: 1,

              "&.Mui-expanded": {
                transform: "none",
                color: "primary.main",
                borderColor: "primary.main",
              },

              "& svg": {
                fontSize: "1.25rem",
              },
            },
          }}
        >
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: "0 1 auto" },
              flexShrink: 0,
              px: 4,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.presentation.deviceName")}
            </Typography>
            <Typography variant={"body1"}>{deviceName}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: "0 1 auto" },
              flexShrink: 0,
              px: 4,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.easyPayOrders.orderNumber")}
            </Typography>
            <Typography variant={"body1"}>{item?.externalCode}</Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: "0 1 auto" },
              flexShrink: 0,
              px: 4,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.easyPayOrders.orderCode")}
            </Typography>
            <Typography variant={"body1"}>
              {item?.internalOrderNumber}
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: "0 1 auto" },
              flexShrink: 0,
              px: 4,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.easyPayOrders.paidAt")}
            </Typography>
            <Typography variant={"body1"} align="center">
              {item?.paidAt ? moment(item?.paidAt).format("HH:mm:ss") : ""}
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: "0 1 auto" },
              flexShrink: 0,
              px: 4,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.easyPayOrders.readyAt")}
            </Typography>
            <Typography variant={"body1"} align="center">
              {item?.completedAt ? (
                moment(item?.completedAt).format("HH:mm:ss")
              ) : (
                <InitialStatusIcon status={item?.completedAt} />
              )}
            </Typography>
          </Div>
          <Div
            sx={{
              flex: { xs: "0 1 auto", md: "0 1 auto" },
              flexShrink: 0,
              px: 4,
            }}
          >
            <Typography
              fontSize={"12px"}
              variant={"h6"}
              color={"text.secondary"}
              mb={0.25}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              {t("pages.easyPayOrders.finishedAt")}
            </Typography>
            <Typography variant={"body1"} align="center">
              {item?.finishedAt ? (
                moment(item?.finishedAt).format("HH:mm:ss")
              ) : (
                <InitialStatusIcon status={item?.finishedAt} />
              )}
            </Typography>
          </Div>
          <Div>
            <Button
              type="submit"
              variant="contained"
              size="normal"
              style={buttonStyle}
              disabled={processStatus === "done"}
              onClick={onPreview}
            >
              {t("pages.easyPayOrders." + processStatus)}
            </Button>
          </Div>
        </AccordionSummary>
      </Accordion>
    </Card>
  );
};
/* Todo item prop define */
export default OrderItem;
