import jwtAuthAxios from "../auth/jwtAuth";

export const getMenu = async (url, customerId) => {
  const { data } = await jwtAuthAxios.get(
    `/restaurant-menu/get?url=${url}&customerId=${customerId}`
  );
  return data.response;
};

export const getMenus = async (customerId) => {
  const { data } = await jwtAuthAxios.get(
    `/restaurant-menu/list?customerId=${customerId}`
  );
  return data.response && data.response.filter(({ type }) => type === "menu");
};

export const addMenu = async (customerId, menu) => {
  const { data } = await jwtAuthAxios.post("/restaurant-menu/add", {
    customerId,
    ...menu,
    type: "menu",
  });
  return data.url;
};

export const editMenu = async (customerId, menu) => {
  const { data } = await jwtAuthAxios.post("/restaurant-menu/update", {
    ...menu,
    customerId,
    type: "menu",
  });
  return data;
};

export const deleteMenu = async (url, customerId) => {
  const { data } = await jwtAuthAxios.delete(
    `/restaurant-menu/delete?url=${url}&customerId=${customerId}`
  );
  return data;
};

export const getRemoteControlMenu = async (
  url,
  customerId,
  username,
  password
) => {
  const { data } = await jwtAuthAxios.get(
    `/menu-get?url=${url}&customerId=${customerId}&username=${username}&password=${password}`
  );
  return data.response;
};

export const updateRemoteControlMenu = async (customerId, menu) => {
  const { data } = await jwtAuthAxios.post("/menu-update", {
    ...menu,
    customerId,
    type: "menu",
  });
  return data;
};

export const updateRemoteControlOffer = async (customerId, menu) => {
  const { data } = await jwtAuthAxios.post("/menu-update", {
    ...menu,
    customerId,
    type: "menu",
  });
  return data;
};
