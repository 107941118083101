import React, { useState } from "react";
import { validateToken } from "app/services/api/partner.js";
import { PartnerAuthContext } from "./PartnerAuthContext";

// Create the provider component
export const PartnerAuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null,
  });
  let user;
  let tokenData;
  const validate = async (token) => {
    try {
      const { data } = await validateToken(token);
      localStorage.setItem("PARTNER_TOKEN", token);
      // localStorage.setItem("PARTNER_USER", JSON.stringify(data));
      tokenData = localStorage.getItem("PARTNER_TOKEN");
      user = data;

      setAuthState({
        isAuthenticated: true,
        user: data,
      });
      return data;
    } catch (error) {
      localStorage.removeItem("PARTNER_TOKEN");
      // localStorage.removeItem("PARTNER_USER");
      throw error;
    }
  };

  return (
    <PartnerAuthContext.Provider
      value={{ ...authState, validate, tokenData, user }}
    >
      {children}
    </PartnerAuthContext.Provider>
  );
};
