"use client";
import Div from "@jumbo/shared/Div";
import { Typography } from "@mui/material";
import Logo404 from "app/shared/Logo/Logo404";
import { headerTheme } from "app/themes/header/default";
import { useTranslation } from "react-i18next";

const InvalidUrl = () => {
  const { t } = useTranslation();
  return (
    <Div
      sx={{
        flex: 1,
        flexWrap: "wrap",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: (theme) => theme.spacing(1),
      }}
    >
      <Logo404 sx={{ mr: 1 }} mode={headerTheme.type ?? "light"} />
      <Typography
        align={"center"}
        component={"h2"}
        variant={"h1"}
        color={"text.secondary"}
        mb={3}
      >
        {t("pages.notFoundPage")}
      </Typography>
    </Div>
  );
};

export default InvalidUrl;
