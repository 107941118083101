import React from "react";
import Div from "@jumbo/shared/Div";
import { ASSET_IMAGES } from "../../utils/constants/paths";

const Logo404 = ({ mini, mode, sx }) => {
  return (
    <Div sx={{ display: "inline-flex", ...sx }}>
      {/* <Link href={'/'}> */}
      {!mini ? (
        <img
          src={
            mode === "light"
              ? `${ASSET_IMAGES}/logo.png`
              : `${ASSET_IMAGES}/logo-white.png`
          }
          alt="Jumbo React"
          style={{ width: "40%", marginLeft: "30%", marginBottom: "2%" }}
        />
      ) : (
        <img
          src={
            mode === "light"
              ? `${ASSET_IMAGES}/logo-short.png`
              : `${ASSET_IMAGES}/logo-short-white.png`
          }
          alt="Jumbo React"
          style={{ width: "20%" }}
        />
      )}
      {/* </Link> */}
    </Div>
  );
};

Logo404.defaultProps = {
  mode: "light",
};

export default Logo404;
