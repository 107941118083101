import { BE_DOMAINS } from "app/utils/constants/settings";
import jwtAuthKioskAxiosProductionPartner from "../auth/jwtAuthKioskProductionPartner";
import jwtAuthKioskAxiosStagingPartner from "../auth/jwtAuthKioskStagingPartner";

export const validateToken = async (token) => {
  const { data } = await jwtAuthKioskAxiosProductionPartner.post(
    "/partner/validateToken",
    {
      token,
    }
  );
  return data;
};

export const getCustomer = async (token, envType = "prod") => {
  const { data } =
    envType === "prod"
      ? await jwtAuthKioskAxiosProductionPartner.get("/partner/customer", {
          headers: { Authorization: `Bearer ${token}` },
        })
      : await jwtAuthKioskAxiosStagingPartner.get("/partner/customer", {
          headers: { Authorization: `Bearer ${token}` },
        });
  // const isDev = process.env.REACT_APP_ENVIRONMENT === "dev";
  // const beDomain = isDev
  //   ? process.env.REACT_APP_KIOSK_STAGING_URL
  //   : BE_DOMAINS[0];
  // jwtAuthKioskAxiosStagingPartner.defaults.baseURL = `${beDomain}/`;
  return data.data;
};

export const getDeviceStatus = async (deviceId, customerUrl, token) => {
  const { data } = await jwtAuthKioskAxiosProductionPartner.get(
    `/partner/deviceStatus`,
    {
      params: { deviceId, customerUrl },
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data;
};

export const updateCustomer = async (dsok, token) => {
  const { data } = await jwtAuthKioskAxiosProductionPartner.put(
    `/partner/customer`,
    dsok,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  return data?.result;
};

export const getOrderList = async (token, envType = "prod") => {
  const { data } =
    envType === "prod"
      ? await jwtAuthKioskAxiosProductionPartner.get(`/partner/order`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      : await jwtAuthKioskAxiosStagingPartner.get(`/partner/order`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  // const { data } = await jwtAuthKioskAxiosProductionPartner.get(
  //   "/partner/order",
  //   {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }
  // );
  return data.data;
};

export const getOrder = async (id, token, envType = "prod") => {
  console.log("getOrderEnvType", envType);
  const { data } =
    envType === "prod"
      ? await jwtAuthKioskAxiosProductionPartner.get(`/partner/order/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      : await jwtAuthKioskAxiosStagingPartner.get(`/partner/order/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
  // const { data } = await jwtAuthKioskAxiosProduction.get(
  //   `/partner/order/${id}`,
  //   {
  //     headers: { Authorization: `Bearer ${token}` },
  //   }
  // );
  return data.data?.length && data.data[0];
};

export const sendEkasaRegister = async (id, token) => {
  const { data } = await jwtAuthKioskAxiosProductionPartner.post(
    "/partner/order/ekasa-register",
    { id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return data?.result;
};
