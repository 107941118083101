import JumboCardQuick from "@jumbo/components/JumboCardQuick/JumboCardQuick";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import Div from "@jumbo/shared/Div/Div";
import { Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { getWidgets } from "app/services/api/home";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ScreenshotMonitorIcon from "@mui/icons-material/ScreenshotMonitor";
import ImageIcon from "@mui/icons-material/Image";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import JumboListNoDataPlaceholder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder/JumboListNoDataPlaceholder";

const LIST_COUNT_LIMIT = 5;

const Home = () => {
  const { t, i18n } = useTranslation();
  const { getAuthUser } = useJumboAuth();
  const { customerId, customer } = getAuthUser();
  const [widgets, setWidgets] = useState({});
  const superadmin = customer?.customerType === 1;

  useEffect(() => {
    getWidgets(customerId).then((response) => {
      setWidgets(response);
    });
  }, []);

  const widgetList = widgets?.widgets || {
    support: true,
    news: true,
  };

  return (
    <Div>
      <Typography variant="h2">{t("pages.home.header")}</Typography>
      <Grid container spacing={2} alignItems={"stretch"}>
        {widgetList.news && (
          <Grid item xs={12} md={8}>
            <JumboCardQuick
              title={
                <Typography variant="h2">
                  {t("sidebar.menuItem.news")}
                </Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
              sx={{ height: "100%" }}
            >
              <Stack>
                <JumboListNoDataPlaceholder width={{ md: 120, xs: 180 }} />
              </Stack>
            </JumboCardQuick>
          </Grid>
        )}
        {widgetList.support && (
          <Grid item xs={12} md={4}>
            <JumboCardQuick
              title={
                <Typography variant="h2">{t("pages.home.contacts")}</Typography>
              }
              headerSx={{ mb: 0, pb: 0 }}
              sx={{ height: "100%" }}
            >
              <Stack spacing={4}>
                <Stack key={"sk"}>
                  <Typography variant="h5">intesso s.r.o.</Typography>
                  <Link href={`mailto:info@datavision.sk`}>
                    <Typography variant="body">info@datavision.sk</Typography>
                  </Link>
                  <Link href={`phone:+421918755096`}>
                    <Typography variant="body">+421 918 755 096</Typography>
                  </Link>
                </Stack>
                <Stack>
                  {superadmin && (
                    <Link href="https://jenkins.gastrokiosk.online/job/Datavision Reverse SSH/lastSuccessfulBuild/artifact/*zip*/archive.zip">
                      <Typography variant="body">
                        {t("pages.home.downloadReverseSSH")}
                      </Typography>
                    </Link>
                  )}
                </Stack>
              </Stack>
            </JumboCardQuick>
          </Grid>
        )}
      </Grid>
    </Div>
  );
};

export default Home;
